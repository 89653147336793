import request from '@/api/request'

// 应用组管理 - 获取应用组列表接口
export function reqAppGroup (params) { return request({ url: '/appgroup/page', params: params }) }

// 应用组管理 - 新增应用组接口
export function reqAppGroupAdd (params) { return request({ url: '/appgroup', params, method: 'post' }) }

// 应用组管理 - 应用组详情接口，通过主键id查询应用组
export function reqAppGroupDetails (params) { return request({ url: '/appgroup/:id', params }) }

// 应用组管理 - 修改应用组接口
export function reqAppGroupEdit (params) { return request({ url: '/appgroup', params, method: 'PUT' }) }

// 应用组管理 - 删除应用组接口，通过主键id删除应用组
// export function reqAppGroupDelete (params) { return request({ url: '/appgroup/:id', params, method: 'delete' }) }
export function reqAppGroupDeleteBatch (ids) { return request({ url: '/appgroup/batch/delete', params: ids, method: 'delete' }) }

/* 关联应用，通过id获取应用列表 */
// export function reqApplication (params) { return request({ url: '/application/:id', params }) }
export function reqApplication (params) { return request({ url: '/application/page', params }) }
/* app端应用 */
export function reqMobileApplication () { return request({ url: '/mobile/application/list' }) }
