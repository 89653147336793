<template>
    <div>
        <div class="appGroupManage">
            <div class="mainTable">
                <TableWrap :total="total" @callback="handlePagination">
                    <template slot="tablesearch">
                        <SearchWrap :searchOptions="searchOptions" :inline="inline" @callback="handleSearchCallback"></SearchWrap>
                    </template>
                    <template slot="tableOperate">
                       <el-button type="primary" size="small" @click="handleAdd">
                            新增
                        </el-button>
                        <el-button type="primary" size="small" @click="handleEdit">
                            修改
                        </el-button>
                        <el-button type="danger" size="small" @click="handleDel">
                            删除
                        </el-button>
                    </template>
                    <el-table ref="agmTable" :data="tableData" :header-cell-style="{background:'#FAFAFA'}" v-loading="loading" @selection-change="handleSelectionChange">
                        <el-table-column type="selection">
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="60">
                            <template v-slot="scope">
                                {{ $utils.getDicValue('SYSTEM_NORMAL_STATUS', scope.row.status).mean }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="code" label="编码" width="240">
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <!-- <el-table-column prop="creator" label="创建人">
                        </el-table-column> -->
                        <el-table-column prop="createTime" label="创建时间">
                        </el-table-column>
                        <!-- <el-table-column prop="updater" label="修改人">
                        </el-table-column> -->
                        <el-table-column prop="updateTime" label="修改时间">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="viewDetails(scope.row)">
                                    详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </TableWrap>
            </div>
        </div>
        <!-- 弹出表单 -->
        <AgmForm ref="refAgmForm" :agmFormTitle="agmFormTitle" :curFormData="curFormData" @callback="handleCallback" />
    </div>
</template>

<script>

import tableMixin from '@/mixIn/tableMixIn'
import { reqAppGroup, reqAppGroupDetails, reqAppGroupDeleteBatch } from '@/api/platform/appGroup'
import AgmForm from './agmForm'

export default {
    /* 应用组管理 */
    name: 'AppGroupManage',

    mixins: [tableMixin],

    components: {
        AgmForm
    },

    data () {
        return {
            searchOptions: [
                { type: 'input', label: '名称', key: 'name' }
            ],
            inline: true,
            reqTableData: reqAppGroup,
            agmFormTitle: '',
            curFormData: {},
            appGroupStatus: []
        }
    },

    created () {
        this.$utils.getDicData('SYSTEM_NORMAL_STATUS').then((res) => {
            this.appGroupStatus = res
            this.getTableData()
        })
    },

    methods: {
        // 点击新增按钮
        handleAdd () {
            this.agmFormTitle = '新增'
            this.curFormData = {
                code: '', // 编码
                name: '', // 名称
                applications: [], // 关联应用组
                status: '', // 状态
                creator: '', // 创建人
                createTime: '', // 创建时间
                updater: '', // 修改人
                updateTime: '' // 修改时间
            }
            this.$refs.refAgmForm.agmFormVisible = true
        },

        // 点击修改按钮
        handleEdit () {
            if (this.multipleSelection.length === 0) {
                this.$message.warning('请先选择您要修改的某行数据！')
            } else if (this.multipleSelection.length > 1) {
                this.$message.warning('修改表格只能选中一行')
                this.$refs.agmTable.clearSelection()
            } else {
                const id = this.multipleSelection[0].id
                this.agmFormTitle = '修改'
                reqAppGroupDetails({
                    id
                }).then((result) => {
                    this.curFormData = result.data
                    this.curFormData.status = `${this.curFormData.status}`
                })
                this.$refs.refAgmForm.agmFormVisible = true
            }
        },

        // 点击删除按钮
        handleDel () {
            // mixin中有自定义的multipleSelection
            const curSel = this.multipleSelection
            const len = curSel.length
            if (len === 1) {
                this.$confirm('确认删除吗？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    // reqAppGroupDelete({
                    //     id: curSel[0].id
                    // }).then((result) => {
                    //     this.getTableData()
                    //     this.$message.success('删除成功！')
                    // })
                    const selIds = []
                    for (let i = 0; i < curSel.length; i++) {
                        selIds.push(curSel[i].id)
                    }
                    reqAppGroupDeleteBatch(selIds).then((result) => {
                        this.getTableData()
                        this.$message.success('删除成功！')
                    })
                }).catch(() => {
                    this.$message.info('已取消删除！')
                })
            } else if (len > 1) {
                this.$message.error('请选择一行数据删除！')
                this.$refs.agmTable.clearSelection()
            } else {
                this.$message.error('请先选择一行数据！')
            }
        },

        // 点击详情按钮
        viewDetails (row) {
            this.agmFormTitle = '详情'
            reqAppGroupDetails({
                id: row.id
            }).then((result) => {
                this.curFormData = result.data
                this.curFormData.status = `${this.curFormData.status}`
            })
            this.$refs.refAgmForm.agmFormVisible = true
        },

        handleCallback () {
            this.getTableData()
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
