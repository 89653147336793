<template>
    <div>
        <el-drawer :title="curAgmFormTit" :visible.sync="agmFormVisible"  direction="rtl" custom-class="cus-drawer">
            <div class="cus_drawer_content">
                <el-form ref="agmFormRef" :model="agmFormData" :rules="agmFormRules" :disabled="isAble">
                    <el-form-item label="编码" prop="code">
                        <el-input size="small" v-model="agmFormData.code"></el-input>
                    </el-form-item>
                    <el-form-item label="名称" prop="name">
                        <el-input size="small" v-model="agmFormData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="关联应用(PC)" prop="applications">
                        <el-select v-model="agmFormData.applications" multiple @change="appSel" style="width:100%" size="small">
                            <el-option v-for="appItem in relAppOptions" :key="appItem.id" :label="appItem.name" :value="appItem.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关联应用(APP)" prop="mobileApplications">
                        <el-select v-model="agmFormData.mobileApplications" multiple @change="appSel" style="width:100%" size="small">
                            <el-option v-for="appItem in relMobileAppOptions" :key="appItem.id" :label="appItem.name" :value="appItem.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="agmFormData.status" style="width:100%" size="small">
                            <el-option v-for="item in statusOptions" :key="item.val" :label="item.mean" :value="item.val"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建人" v-show="showOrHide">
                        <el-input size="small" v-model="agmFormData.creator"></el-input>
                    </el-form-item>
                    <el-form-item label="创建时间" v-show="showOrHide">
                        <el-date-picker size="small" v-model="agmFormData.createTime" type="datetime" style="width:100%"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="修改人" v-show="showOrHide">
                        <el-input size="small" v-model="agmFormData.updater"></el-input>
                    </el-form-item>
                    <el-form-item label="修改时间" v-show="showOrHide">
                        <el-date-picker size="small" v-model="agmFormData.updateTime" type="datetime" style="width:100%"></el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="cus_drawer_footer">
                    <el-button size="small" plain @click="agmFormVisible = false">
                        取消
                    </el-button>
                    <el-button type="primary" size="small" @click="confirmSubmit('agmFormRef')">
                        提交
                    </el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>

import { reqAppGroupAdd, reqAppGroupEdit, reqApplication, reqMobileApplication } from '@/api/platform/appGroup'
import dayjs from 'dayjs'
// import { reqApply } from '@/platform/apply'

export default {
    /* 应用组管理-表单 */
    name: 'AgmForm',

    props: {
        agmFormTitle: {
            type: String,
            default: ''
        },
        curFormData: {
            type: Object,
            default: () => {}
        }
    },

    data () {
        return {
            curAgmFormTit: '',
            agmFormVisible: false,
            agmFormData: {
                code: '', // 编码
                name: '', // 名称
                applications: [], // 关联应用组
                mobileApplications: [], // 关联应用组APP
                status: '', // 状态
                creator: '', // 创建人
                createTime: '', // 创建时间
                updater: '', // 修改人
                updateTime: '' // 修改时间
            },
            relAppOptions: [],
            relMobileAppOptions: [],
            reqId: [],
            statusOptions: [],
            // 详情禁用
            isAble: false,
            // 详情显示内容
            showOrHide: false,
            // 表单校验
            agmFormRules: {
                code: [
                    { required: true, message: '请输入编码（必填）', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入名称（必填）', trigger: 'blur' }
                ],
                // applications: [
                //     { type: 'array', required: false, message: '请选择关联应用', trigger: 'change' }
                // ],
                // mobileApplications: [
                //     { type: 'array', required: false, message: '请选择关联应用', trigger: 'change' }
                // ],
                status: [
                    { required: true, message: '请选择状态（必选）', trigger: 'change' }
                ]
            }
        }
    },

    created () {
        // 下拉多选-获取关联应用 PC
        reqApplication({ size: 100, current: 1 }).then((res) => {
            res.data.records.forEach(e => {
                this.relAppOptions.push({
                    id: e.id,
                    name: e.name
                })
            })
        })
        // 下拉多选-获取关联应用 APP
        reqMobileApplication().then((res) => {
            res.data.forEach(e => {
                this.relMobileAppOptions.push({
                    id: e.id,
                    name: e.name
                })
            })
        })

        // 获取应用组状态
        this.$utils.getDicData('SYSTEM_NORMAL_STATUS').then((res) => {
            this.statusOptions = res
        })
    },

    watch: {
        agmFormTitle: {
            handler (newVal) {
                this.curAgmFormTit = newVal
                if (this.curAgmFormTit === '详情') {
                    this.isAble = true
                    this.showOrHide = true
                } else {
                    this.isAble = false
                    this.showOrHide = false
                }
            }
        },
        curFormData: {
            handler (newVal) {
                const a = { ...newVal }
                const pc = []
                const app = []
                a.applications.forEach(v => {
                    if (v.catalogType === '2') {
                        app.push(v.id)
                    } else {
                        pc.push(v.id)
                    }
                })
                a.applications = pc
                a.mobileApplications = app
                this.agmFormData = a
            },
            deep: true
        },
        agmFormVisible: {
            handler (newVal) {
                if (newVal !== '') {
                    if (!this.$refs.agmFormRef) {
                        this.$nextTick(() => {
                            this.$refs.agmFormRef.resetFields()
                        })
                    } else {
                        this.$refs.agmFormRef.resetFields()
                    }
                }
            }
        }
    },

    methods: {

        // 下拉多选
        appSel (val) {
            // val = this.agmFormData.applications.map((curVal) => {
            //     return {
            //         id: curVal
            //     }
            // })
        },

        // 提交
        confirmSubmit (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.curAgmFormTit === '新增') {
                        this.agmFormData.createTime = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
                        const params = Object.assign({}, this.agmFormData)
                        const pc = this.agmFormData.applications.map((curVal) => ({ id: curVal }))
                        const mobile = this.agmFormData.mobileApplications.map((curVal) => ({ id: curVal }))
                        params.applications = pc
                        params.mobileApplications = mobile
                        reqAppGroupAdd(params).then((result) => {
                            result.data = params
                            this.$emit('callback')
                            this.agmFormVisible = false
                            this.$message.success('新增成功！')
                        })
                    }
                    if (this.curAgmFormTit === '修改') {
                        this.agmFormData.updateTime = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
                        const params = Object.assign({}, this.agmFormData)
                        const pc = this.agmFormData.applications.map((curVal) => ({ id: curVal }))
                        const mobile = this.agmFormData.mobileApplications.map((curVal) => ({ id: curVal }))
                        params.applications = pc
                        params.mobileApplications = mobile
                        reqAppGroupEdit(params).then((result) => {
                            result.data = params
                            this.$emit('callback')
                            this.agmFormVisible = false
                            this.$message.success('修改成功！')
                        }).catch((error) => {
                            console.error(error)
                        })
                    }
                } else {
                    this.$message.error('打 * 号的选项不能为空！')
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
